<script>
import { Bar } from 'vue-chartjs'
export default {
  name: 'BarChart',
  extends: Bar,
  props: {
    dataChart: {
      type: Array,
      default: () => []
    },
    maxY: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      options: {
        tooltips: {
          mode: 'label',
          intersect: false,
          callbacks: {
            label: function(tooltipItems, data) {
              if (tooltipItems.yLabel === '0') {
                return ''
              }
              return data.datasets[tooltipItems.datasetIndex].label + '：' + tooltipItems.yLabel + '%'
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        title: {
          display: false
        },
        layout: {
          padding: {
            bottom: 0
          }
        },
        scales: {
          xAxes: [{
            ticks: {
              autoSkip: false,
              fontSize: 10
            }
          }],
          yAxes: [{
            type: 'linear',
            position: 'left',
            ticks: {
              min: 0,
              max: this.maxY,
              stepSize: 1,
              userCallback: function(tick) {
                return tick.toString() + '%'
              }
            }
          }]
        }
      }
    }
  },
  mounted() {
    const data = {
      labels: this.dataChart.map(item => item.text),
      datasets: [{
        label: '推定購入意向増加率',
        maxBarThickness: 28,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        data: this.dataChart.map(item => item.percent)
      }]
    }
    this.renderChart(data, this.options)
  }
}
</script>

<style scoped>

</style>
