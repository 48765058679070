<template>
  <div class="result">
    <div class="row">
      <div class="col-sm-12">
        <div class="panel">
          <div class="panel-heading">
            <h2 class="pageTitle">
              {{ title }}<span>Results</span>
            </h2>
            <div class="rank-wrap">
              <span class="rank-line">ランク区分：</span> <br>
              <span class="rank-line">(A）5％以上 &nbsp;&nbsp; (B）3%以上5%未満</span> <br>
              <span class="rank-line">(C）1％以上3％未満  &nbsp;&nbsp; (D）1％未満</span>
            </div>
          </div>
          <div v-if="dataChart.length > 0" class="panel-body">
            <div>
              <div class="row">
                <div class="col-sm-12">
                  <BarChart
                    :max-y="maxY"
                    :data-chart="dataChart"
                  />
                </div>
              </div>
              <div class="row mgt10">
                <div class="col-sm-12">
                  <table class="scoreTable table-bordered table-striped">
                    <tbody>
                      <tr>
                        <th>購入意向(％)</th>
                        <td v-for="(item, index) in dataChart" :key="index">
                          {{ item.percent }}
                        </td>
                      </tr>
                      <tr>
                        <th>貢献度ランク</th>
                        <td v-for="(item, index) in dataChart" :key="index">
                          {{ item.rank }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <ul class="actionVertical clearfix">
              <li>
                <button type="submit" class="btn btn01" @click="exportZip">
                  ダウンロード
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from './BarChart'
import store from '../../store'
import { formatPercent, formatRank, getMaxY, sortDeepArrayByValue } from '../../shared/common'
import { exportMixin } from '../../shared/mixins/export'
import { actions as actionsApp } from '../../store/modules/app'
import { titleResultCustom } from '../../common/constant'
import { exportZipResultApi } from '../../services/api'

export default {
  name: 'ResultCustom',
  components: { BarChart },
  mixins: [exportMixin],
  data() {
    return {
      result: [],
      dataChart: [],
      maxY: 0,
      title: titleResultCustom
    }
  },
  mounted() {
    this.getDataChart()
  },
  methods: {
    getDataChart() {
      this.result = store.getters.result
      const array = []
      for (const [indexCategory, category] of Object.entries(this.result)) {
        for (const [indexCharacter, character] of Object.entries(category)) {
          array.push({
            text: `${indexCharacter}の${indexCategory}`,
            value: character,
            rank: formatRank(character),
            percent: formatPercent(character)
          })
        }
      }
      this.dataChart = sortDeepArrayByValue(array)
      this.maxY = getMaxY(this.result)
    },
    async exportZip() {
      if (Object.keys(store.getters.result).length !== 0) {
        await store.dispatch(actionsApp.SET_LOADING, { active: true })
        const csv = this.createArrayCSVMixins(store.getters.result)
        const pdf = this.formatDataPdfMixins(this.dataChart, store.getters.menu, this.title)
        const res = await exportZipResultApi({
          max_y: this.maxY,
          csv,
          pdf
        })
        if (res.status === 200) {
          await this.exportZipMixins(res.data)
        } else {
          alert('Error! Please try again')
        }
        await store.dispatch(actionsApp.SET_LOADING, { active: false })
      }
    }
  }
}
</script>

<style scoped>
.result {
  position: relative;
}
.rank-wrap {
  float: right;
  color: #FFFFFF;
  font-size: 12px;
  margin-top: -4px;
}
.rank-line {
  line-height: 18px;
}
</style>
