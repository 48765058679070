<template>
  <main id="results">
    <div class="container -large">
      <ResultCustom
        v-if="menu === menuConstant.MENU_1"
      />
      <ResultRecommend
        v-if="menu === menuConstant.MENU_2"
      />
    </div>
  </main>
</template>

<script>
import ResultCustom from '../../components/project/ResultCustom'
import store from '../../store'
import { MENU } from '../../common/constant'
import ResultRecommend from '../../components/project/ResultRecommend'
export default {
  name: 'Result',
  components: { ResultCustom, ResultRecommend },
  data() {
    return {
      menuConstant: MENU
    }
  },
  computed: {
    menu() {
      return store.getters.menu
    }
  },
  async created() {
    if (!store.getters.menu) {
      await this.$router.push({ name: 'SelectMenu' })
      this.$destroy()
      return false
    }
  }
}
</script>

<style scoped>

</style>
